import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Cookies from 'js-cookie'

const propTypes = {
  description: PropTypes.string.isRequired,
  policyLinkText: PropTypes.string.isRequired,
  policyLinkUrl: PropTypes.string.isRequired
}

class CookiesConsenter extends Component {
  constructor () {
    super()

    this.state = {
      hasConsented: Cookies.get('eu_cookies_consented') || false
    }

    this.consent = this.consent.bind(this)
  }

  consent () {
    Cookies.set('eu_cookies_consented', true, { expires: 365 })
    this.setState({ hasConsented: true })
  }

  render () {
    return (
      <div
        className={cn({
          'cookies-consenter': true,
          'cookies-consenter--visible': this.state.hasConsented === false
        })}
      >
        <div className='contained'>
          <p className='cookies-consenter__description'>
            {this.props.description}
            &nbsp;
            <a
              className='cookies-consenter__policy-link'
              href={this.props.policyLinkUrl}
            >
              {this.props.policyLinkText}
            </a>
            .
          </p>
          <button
            className='cookies-consenter__consent-button'
            onClick={this.consent}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='cookies-consenter__x'
              onClick={this.consent}
              width='24'
              height='24'
              viewBox='0 0 24 24'
            >
              <path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' />
            </svg>
          </button>
        </div>
      </div>
    )
  }
}

CookiesConsenter.propTypes = propTypes

export default CookiesConsenter
