require('./services/webFonts.es6.js')
const GoogleAnalytics = require('services/googleAnalytics.es6.js').default
const GooglePublisherTag = require('services/googlePublisherTag.es6.js').default

const gaManager = global.GoogleAnalyticsManager = new GoogleAnalytics()
const gptManager = global.GooglePublisherTagManager = new GooglePublisherTag()


gaManager.setup({
  analyticsId: `${process.env.GOOGLE_ANALYTICS_ID}`,
  domains: ["staging.airport-desk.com", "staging.airportdesk.nl", "staging.airportdesk.de", "staging.airportdesk.se", "staging.airportdesk.es", "staging.airportdesk.fr", "staging.airportdesk.it", "staging.airportdesk.pt", "staging.airportdesk.no", "staging.airportdesk.fi", "staging.airportdesk.dk"]
})



gptManager.enable()

