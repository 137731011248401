import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import Turbolinks from 'turbolinks'

import '../polyfills.js'
import '../services.js.erb'

// Configure Stimulus, Rails, Turbolinks
const application = Application.start()
const context = require.context('controllers', true, /\.js$/)
application.load(definitionsFromContext(context))
Turbolinks.start()

// Configure React
var ReactRailsUJS = require('react_ujs')
var componentRequireContext = require.context('../components', true)
ReactRailsUJS.useContext(componentRequireContext)
