export default class GooglePublisherTag {
  constructor () {
    this.slots = {};
    this.resizeThrottler = null;
    window.googletag = (typeof googletag != 'undefined') ? googletag : {};
    window.googletag.cmd = googletag.cmd || [];

    document.addEventListener('turbolinks:before-cache', () => {
      this._clearSlots();
    }, true);

    document.addEventListener('turbolinks:load', () => {
      this._evaluate();
      this.windowWidth = window.innerWidth;
    }, true);

    window.addEventListener('resize', () => {
      if (window.innerWidth == this.windowWidth) return;
      this.windowWidth = window.innerWidth;

      clearTimeout(this.resizeThrottler);
      this.resizeThrottler = setTimeout(() => {
        const slots = Object.keys(this.slots).map(key => this.slots[key]);
        this._refreshSlots(slots);
      }, 250);
    }, true);

    this._loadApi();
  }

  _evaluate () {
    if (!this.enabled === true) return;

    const elements = document.getElementsByClassName('gpt-ad');

    for (let i = elements.length; i--;) {
      const elem = elements[i];
      const cachedSlot = this.slots[elem.id];

      if (cachedSlot) {
        this._refreshSlots([cachedSlot]);
      } else {
        this._defineSlot(elem);
      }
    }
  }

  _defineSlot (elem) {
    const divId = elem.id;
    const path = elem.getAttribute('data-gpt-path');
    const sizeMapping = JSON.parse(elem.getAttribute('data-gpt-size-mapping'));

    googletag.cmd.push(() => {
      const slot = googletag.defineSlot(path, sizeMapping[0][0], divId).
                     defineSizeMapping(sizeMapping).
                     setCollapseEmptyDiv(true).
                     addService(googletag.pubads());
      googletag.enableServices();
      googletag.display(divId);

      this.slots[divId] = slot;
    });
  }

  _refreshSlots (slots) {
    googletag.cmd.push(function() {
      googletag.pubads().refresh(slots);
    });
  }

  _clearSlots () {
    googletag.cmd.push(function() {
      googletag.pubads().clear();
    });
  }

  _loadApi () {
    let element = document.createElement('script');
    element.id = 'gpt-api';
    element.type = 'text/javascript';
    element.async = true;
    element.src = `${(('https:' == document.location.protocol ? 'https' : 'http'))}://www.googletagservices.com/tag/js/gpt.js`;

    let node = document.getElementsByTagName('script')[0];
    node.parentNode.insertBefore(element, node);
  }

  enable () {
    this.enabled = true;
  }
}
