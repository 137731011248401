import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'
import reactMixin from 'react-mixin'
import LocalStorageMixin from 'react-localstorage'
import Promise from 'promise-polyfill'
import fetch from 'unfetch'
import Fuse from 'fuse.js'
import cn from 'classnames'
import { ChasingDots } from 'better-react-spinkit'
import Turbolinks from 'turbolinks'

if (!window.Promise) {
  window.Promise = Promise
}

const propTypes = {
  remotePath: PropTypes.string.isRequired,
  placeholderText: PropTypes.string,
  locale: PropTypes.string
}

const defaultProps = {
  placeholderText: 'Search airports',
  locale: 'en',
  stateFilterKeys: ['data']
}

function highlight (text, query) {
  const matches = AutosuggestHighlightMatch(text, query.trim())
  const parts = AutosuggestHighlightParse(text, matches)

  return parts.map(part => {
    const className = part.highlight ? 'highlight' : null

    return (
      <span className={className} key={part.text}>
        {part.text}
      </span>
    )
  })
}

const getSuggestionValue = suggestion => suggestion.name
const renderSuggestion = (suggestion, { query }) => (
  <div>
    <div className='react-autosuggest__title'>
      {highlight(`${suggestion.name} (${suggestion.iata_code})`, query)}
    </div>
    <div className='react-autosuggest__subtitle'>
      {highlight(`${suggestion.city_name}, ${suggestion.country_name}`, query)}
    </div>
  </div>
)

function onSuggestionSelected (event, { suggestion }) {
  Turbolinks.visit(suggestion.url)
}

class SearchBar extends Component {
  constructor () {
    super()

    this.state = {
      data: [],
      suggestions: [],
      value: '',
      didUpdateData: false,
      isLoading: false,
      isActive: false
    }

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(
      this
    )
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(
      this
    )
    this.onIconClick = this.onIconClick.bind(this)
  }

  onFocus () {
    if (!this.state.didUpdateData) this.getData()

    this.setState({
      didUpdateData: true,
      isActive: true
    })
  }

  onBlur () {
    this.setState({
      isActive: false
    })
  }

  onChange (event, { newValue }) {
    this.setState({
      value: newValue
    })
  }

  onSuggestionsFetchRequested (value) {
    this.setState({
      suggestions: this.getSuggestions(value)
    })
  }

  onSuggestionsClearRequested () {
    this.setState({
      suggestions: []
    })
  }

  onIconClick () {
    this.inputElement.focus()
    this.inputElement.setSelectionRange(0, this.inputElement.value.length)
  }

  getData () {
    if (!this.state.data.length) {
      this.setState({
        isLoading: true
      })
    }

    fetch(`${this.props.remotePath}?locale=${this.props.locale}`, {
      credentials: 'same-origin'
    })
      .then(response => response.json())
      .then(response => {
        this.setState({
          data: response,
          isLoading: false
        })

        this.focus()
      })
  }

  getSuggestions (value) {
    const options = {
      shouldSort: true,
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 26,
      minMatchCharLength: 1,
      keys: ['name', 'city_name', 'country_name']
    }
    const fuse = new Fuse(this.state.data, options)

    return fuse.search(value.value)
  }

  focus () {
    this.inputElement.blur()
    this.inputElement.focus()
  }

  render () {
    const { value, suggestions } = this.state
    const inputProps = {
      placeholder: this.props.placeholderText,
      autoCorrect: 'off',
      spellCheck: 'false',
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      onChange: this.onChange,
      value
    }

    return (
      <div
        className={cn({
          'search-bar': true,
          'search-bar--active': this.state.isActive
        })}
      >
        <Autosuggest
          ref={autosuggest => {
            if (autosuggest && autosuggest.input) {
              this.inputElement = autosuggest.input
            }
          }}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        {!this.state.isLoading && (
          <svg
            onClick={this.onIconClick}
            className='search-bar__icon'
            width='1792'
            height='1792'
            viewBox='0 0 1792 1792'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M1216 832q0-185-131.5-316.5t-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5 316.5-131.5 131.5-316.5zm512 832q0 52-38 90t-90 38q-54 0-90-38l-343-342q-179 124-399 124-143 0-273.5-55.5t-225-150-150-225-55.5-273.5 55.5-273.5 150-225 225-150 273.5-55.5 273.5 55.5 225 150 150 225 55.5 273.5q0 220-124 399l343 343q37 37 37 90z' />
          </svg>
        )}
        {this.state.isLoading && (
          <ChasingDots size={14} className='search-bar__loader' />
        )}
      </div>
    )
  }
}

SearchBar.propTypes = propTypes
SearchBar.defaultProps = defaultProps

reactMixin.onClass(SearchBar, LocalStorageMixin)

export default SearchBar
