/* global mapkit */
import { Controller } from 'stimulus'
import Turbolinks from 'turbolinks'
import fetchJS from 'fetch-js'

const MAPKIT_URL = 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js'

export default class extends Controller {
  static targets = ['container']

  initialize () {
    const selectedId = this.data.get('selected-id')

    fetchJS(MAPKIT_URL, () => {
      this.initMap()
      this.addAnnotations()
      if (selectedId) this.presentSelectedAnnotation(selectedId)
    })
  }

  presentSelectedAnnotation (selectedId) {
    const annotation = this.map.annotations.find(
      annotation => annotation.airport.id === parseInt(selectedId, 10)
    )

    this.map.showItems(annotation, this.mapShowItemsOptions())
  }

  addAnnotations () {
    const airports = JSON.parse(this.data.get('airports'))
    const annotations = this.annotationsFromAirports(airports)

    this.map.showItems(annotations, this.mapShowItemsOptions())
  }

  annotationsFromAirports (airports) {
    return airports.map(airport => {
      const annotation = new mapkit.MarkerAnnotation(
        new mapkit.Coordinate(...airport.coordinate),
        {
          color: '#cd3850',
          title: airport.name,
          glyphImage: {
            1: airport.glyph_image
          }
        }
      )
      annotation.airport = airport
      annotation.addEventListener('select', this.onAnnotationSelect)
      return annotation
    })
  }

  onAnnotationSelect (event) {
    const airport = event.target.airport
    Turbolinks.visit(airport.path)
  }

  mapShowItemsOptions () {
    return {
      minimumSpan: new mapkit.CoordinateSpan(0.1, 0.1)
    }
  }

  initMap () {
    mapkit.init({
      authorizationCallback: done => {
        fetch(this.data.get('remote-token-path'), {
          credentials: 'same-origin'
        })
          .then(res => res.text())
          .then(done)
          .catch(error => {
            console.log(error)
          })
      },
      language: this.data.get('language')
    })

    this.map = new mapkit.Map(this.containerTarget)
    this.map.tintColor = '#0f465a'
  }
}
