require('autotrack/lib/plugins/event-tracker');
require('autotrack/lib/plugins/url-change-tracker');

export default class GoogleAnalytics {
  constructor () {
    this._loadApi();
  }

  _loadApi () {
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
  }

  setup ({ analyticsId, domains }) {
    ga('create', analyticsId, 'auto', { 'allowLinker': true });
    ga('require', 'eventTracker');
    ga('require', 'urlChangeTracker');
    ga('require', 'displayfeatures');
    ga('require', 'linker');
    ga('linker:autoLink', domains);
    ga('send', 'pageview');
  }
}
