import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Promise from 'promise-polyfill'
import fetch from 'unfetch'
import renderHTML from 'react-render-html'

if (!window.Promise) {
  window.Promise = Promise
}

const propTypes = {
  remotePath: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  category: PropTypes.string
}

const defaultProps = {
  category: null
}

class Affiliate extends Component {
  constructor () {
    super()

    this.state = {
      content: null
    }
  }

  componentDidMount () {
    fetch(this.fullRemotePath(), {
      credentials: 'same-origin'
    })
      .then(response => {
        if (response.status === 204) return null
        return response.json()
      })
      .then(response => {
        if (!response) return
        this.setState({
          content: response.html_content
        })
      })
  }

  fullRemotePath () {
    let path = `${this.props.remotePath}?locale=${this.props.locale}`
    if (this.props.category) path += `&category=${this.props.category}`
    return path
  }

  render () {
    return (
      <div className='affiliate'>
        {this.state.content && renderHTML(this.state.content)}
      </div>
    )
  }
}

Affiliate.propTypes = propTypes
Affiliate.defaultProps = defaultProps

export default Affiliate
